document.addEventListener("turbo:load", () => {
  const APP_TYPES_FOR_OFFICIAL_INTEGRATION = ["Partner"];
  const APP_TYPES_REQUIRING_ACCOUNT_EMAIL = ["Reseller", "Core Customer"];
  const APP_TYPES_FOR_TOGGLING_PCRX = ["CDDS"];

  $("#app_app_type_id").change(() => {
    const selectedAppType = $("#app_app_type_id").find(":selected").text();
    const officialIntegrationSection = $("#app_official_integration_section");
    const linkedAccountIdSection = $("#app_email_section");
    const togglePCRXIdSection = $("#app_pcrx_section");

    if (APP_TYPES_FOR_OFFICIAL_INTEGRATION.includes(selectedAppType)) {
      officialIntegrationSection.show();
    } else {
      $("input:checkbox#app_official_integration").removeAttr("checked");
      officialIntegrationSection.hide();
    }

    if (APP_TYPES_REQUIRING_ACCOUNT_EMAIL.includes(selectedAppType)) {
      linkedAccountIdSection.show();
    } else {
      $("input#app_linked_account_id").val("");
      linkedAccountIdSection.hide();
    }

    if (APP_TYPES_FOR_TOGGLING_PCRX.includes(selectedAppType)) {
      togglePCRXIdSection.show();
    } else {
      togglePCRXIdSection.hide();
    }
  });
});
