const fund = (($) => {
  /**
   * When a fund is successfully assigned to an app, remove its search entry and add it to the assigned table
   * @param {*} uuid - UUID of the fund listed in the search entries (this entry will be removed)
   * @param {*} content - HTML content of the fund for the new fund entry
   */
  function assignFund(uuid, content) {
    removeSearchEntry(uuid);

    if (content) {
      addAssignedEntry(content);
      removeEmptyFundsNotice();
    }
  }

  /**
   * Removed the fund when it is unassigned from an app
   * @param {String} uuid - UUID of the fund assigned to the app
   * @return {void}
   */
  function unassignFund(uuid) {
    if (!uuid) return;
    $(`#fund_${uuid}`).remove();

    // If no funds are assigned to the fund display a notice
    const fundList = $("#funds-table-body");
    if (fundList && fundList.children().length === 0) {
      const emptyFundsNotice =
        '<tr id="emptyFundsNotice"><td colspan="5">No funds are currently assigned to this app.</td></tr>';
      $("#funds-table").append(emptyFundsNotice);
    }
  }

  /**
   * Remove the notice stating that there are no assigned funds
   * @note - this should only be called when an an assigned fund is added to the fund list and the original list was empty
   * */
  function removeEmptyFundsNotice() {
    const emptyFundsNotice = $("#emptyFundsNotice");
    if (emptyFundsNotice) {
      emptyFundsNotice.remove();
    }
  }

  /**
   * Remove the fund entry in the listed search results
   * @param {String} uuid - UUID of the fund lists in the search results
   * @return {void}
   */
  function removeSearchEntry(uuid) {
    if (!uuid) return;

    const entry = $(`#new_fund_${uuid}`);
    if (entry) {
      entry.remove();
    }
  }

  /**
   * Add the fund to the assigned entry table
   * @param {String} content - New fund <tr> entry
   * @return {void}
   */
  function addAssignedEntry(content) {
    if (!content) return;

    $("#funds-table-body").append(content);
  }

  /**
   * If a list of funds was returned this will enable displaying them
   * @param {String} content
   * @returns {void}
   */
  function displaySearchResults(content) {
    if (!content) return;

    const fundList = $("#fund-lookup-list");
    fundList.html(content);
  }

  return {
    assignFund: assignFund,
    unassignFund: unassignFund,
    displaySearchResults: displaySearchResults,
  };
})(jQuery);

window.fund = fund;
