const portfolio = (($) => {
  /**
   * When a portfolio is successfully assigned to an app, remove its search entry and add it to the assigned table
   * @param {*} uuid - UUID of the portfolio listed in the search entries (this entry will be removed)
   * @param {*} content - HTML content of the portfolio for the new portfolio entry
   */
  function assignPortfolio(uuid, content) {
    removeSearchEntry(uuid);

    if (content) {
      addAssignedEntry(content);
      removeEmptyPortfoliosNotice();
    }
  }

  /**
   * Removed the portfolio when it is unassigned from an app
   * @param {String} uuid - UUID of the portfolio assigned to the app
   * @return {void}
   */
  function unassignPortfolio(uuid) {
    if (!uuid) return;
    $(`#portfolio_${uuid}`).remove();

    // If no portfolios are assigned to the portfolio display a notice
    const portfolioList = $("#portfolios-table-body");
    if (portfolioList && portfolioList.children().length === 0) {
      const emptyPortfoliosNotice =
        '<tr id="emptyPortfoliosNotice"><td colspan="5">No portfolios are currently assigned to this app.</td></tr>';
      $("#portfolios-table").append(emptyPortfoliosNotice);
    }
  }

  /**
   * Remove the notice stating that there are no assigned portfolios
   * @note - this should only be called when an an assigned portfolio is added to the portfolio list and the original list was empty
   * */
  function removeEmptyPortfoliosNotice() {
    const emptyPortfoliosNotice = $("#emptyPortfoliosNotice");
    if (emptyPortfoliosNotice) {
      emptyPortfoliosNotice.remove();
    }
  }

  /**
   * Remove the portfolio entry in the listed search results
   * @param {String} uuid - UUID of the portfolio lists in the search results
   * @return {void}
   */
  function removeSearchEntry(uuid) {
    if (!uuid) return;

    const entry = $(`#new_portfolio_${uuid}`);
    if (entry) {
      entry.remove();
    }
  }

  /**
   * Add the portfolio to the assigned entry table
   * @param {String} content - New portfolio <tr> entry
   * @return {void}
   */
  function addAssignedEntry(content) {
    if (!content) return;

    $("#portfolios-table-body").append(content);
  }

  /**
   * If a list of portfolios was returned this will enable displaying them
   * @param {String} content
   * @returns {void}
   */
  function displaySearchResults(content) {
    if (!content) return;

    const portfolioList = $("#portfolio-lookup-list");
    portfolioList.html(content);
  }

  return {
    assignPortfolio: assignPortfolio,
    unassignPortfolio: unassignPortfolio,
    displaySearchResults: displaySearchResults,
  };
})(jQuery);

window.portfolio = portfolio;
