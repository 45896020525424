import "@hotwired/turbo-rails";
import * as ActiveStorage from "@rails/activestorage";
import Rails from "@rails/ujs"; // to allow UJS calls from the views (until this can all be replaced with turbo/hotwire)

// Entry point for the build script in your package.json
import "./src/plugins/jquery";
import * as bootstrap from "bootstrap";
import "./src/plugins/bootstrap_utils";

import "./src/plugins/highcharts";
import "trix";
import "@rails/actiontext";

import "./channels"; // For ActionCable Channels
import "./controllers"; // For StimulusJS Controllers
import "./src/admin";
import "./src/due_diligence";
// So Turbo isn't enabled on all forms, links, etc, by default
// This allows a gradual transition of implementing of turboframes
Turbo.session.drive = true;
window.Rails = Rails;
Rails.start();
ActiveStorage.start();
window.bootstrap = bootstrap;

// Prevent Files/Attachments from being added to ANY of the Trix/ActionText fields
document.addEventListener("trix-file-accept", (event) => {
  event.preventDefault();
  alert("File attachment not supported!");
});
