document.addEventListener("turbo:load", () => {
  const DATA_SETS_WITH_TIME_PERIODS = ["Carbon Estimates", "Energy Estimates"];

  $("#app_product_offering_data_set_category_id").change(() => {
    const selectedDataSet = $("#app_product_offering_data_set_category_id")
      .find(":selected")
      .text();
    const timePeriodSection = $("#timePeriods");

    if (DATA_SETS_WITH_TIME_PERIODS.includes(selectedDataSet)) {
      timePeriodSection.show();
    } else {
      timePeriodSection.hide();
    }
  });
});
