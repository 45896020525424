const watchForToggledFeatures = () => {
  // re-initialize all event listeners on the new checkboxes
  $("input.form-check-input[type=checkbox]").change(function () {
    const formElement = this.closest("form");
    setTimeout(() => {
      // Submit the featureFlag change with UJS
      Rails.fire(formElement, "submit");
    }, 350);
  });
};
window.watchForToggledFeatures = watchForToggledFeatures;

const watchForIndividualToggledFeature = (toggleId) => {
  $(toggleId).change(function () {
    const formElement = this.closest("form");
    setTimeout(() => {
      // Submit the featureFlag change with UJS
      Rails.fire(formElement, "submit");
    }, 350);
  });
};
window.watchForIndividualToggledFeature = watchForIndividualToggledFeature;
